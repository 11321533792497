<template>
	<form-item label="Domain Names" style="width:400px;">
		<el-input placeholder="请选择" @focus="visible=true" size="mini"></el-input>
		<small @click="visible=true" style="cursor:pointer;display:inline-block;width: 150px;margin-left:10px">已选择{{localeDomainNames.length}}</small>
		<el-dialog title="Domain Names" :visible.sync="visible">
			<div>
				<div>
					<el-input
					  v-model="filterDomainName" 
					  placeholder="快速过滤" 
					  clearable size="mini" class="mb-3"></el-input>
				</div>
				<div>
					<el-button type="text" @click="initDomainNames()" :disabled="refreshing">刷新</el-button>
					<el-button type="text" @click="addAll" class="text-success">全选</el-button>
					<el-button type="text" @click="removeAll" class="text-danger">清除</el-button>
				</div>
			    <el-checkbox-group v-model="localeDomainNames" class="terms-domain-checkbox-grid" size="mini">
			      <el-checkbox 
			        v-for="item,index in filteredDomainNames" 
			        :key="index" 
			        :label="item">
			        {{ item }}
			      </el-checkbox>
			    </el-checkbox-group>
			</div>
		</el-dialog>
	</form-item>
</template>

<script>
	export default {
		props: {
			domainNames: {
				type: Array,
				default: (() => [])
			},
			namespace: {
				type: String,
				default: 'jscx'
			},
			hasDirection: {
				type: Boolean,
				default: null
			}
		},
		data() {
			return {
				allDomainNames: [],
				visible: false,
				filterDomainName: '',
				refreshing: false
			}
		},
		computed: {
			localeDomainNames: {
				get() {
					return this.domainNames
				},
				set(value) {
					this.$emit("update:domainNames", value)
				}
			},
			sortedDomainNames() {
				return this.allDomainNames.sort((a, b) => a.localeCompare(b))
			},
			filteredDomainNames() {
				return this.sortedDomainNames.filter(v => (new RegExp(this.filterDomainName, "ig")).test(v))
			},
		},
		mounted() {
			this.initDomainNames()
		},
		methods: {
			addAll() {
				this.filteredDomainNames.forEach(el => {
					if (this.localeDomainNames.includes(el) === false) {
						this.localeDomainNames.push(el)
					}
				})
			},
			removeAll() {
				this.filteredDomainNames.forEach(el => {
					const i = this.localeDomainNames.indexOf(el)
					if (i !== -1) {
						this.localeDomainNames.splice(i, 1)
					}
				})
			},
			initDomainNames() {
				const maxRetries = 3;
				// 封装重试逻辑的函数
				const fetchWithRetry = (action, retries) => {
					this.refreshing = true
					this.$store.dispatch(`${this.namespace}/${action}`, {hasDirection: this.hasDirection})
						.then(res => {
							this.refreshing = false
							this.allDomainNames = res.map(v => v.domainName); // 成功时设置资源
						})
						.catch(() => {
							this.refreshing = false
							if (retries > 0) {
								console.warn(`重试剩余重试次数: ${retries}`);
								fetchWithRetry(action, retries - 1); // 失败时递归重试
							} else {
								console.error(`${resourceKey} 获取失败，已达到最大重试次数`);
							}
						});
				};
				// 调用重试方法获取资源
				fetchWithRetry('getDomains', maxRetries);
			},
		}
	}
</script>

<style>
</style>