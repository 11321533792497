<template>
	<el-table class="jscx-terms-table" height="auto" ref="multipleTable" :data="tableData" @select="pinSelect"
		@sort-change="$emit('sortChange', $event)" size="mini" border :default-sort = "{prop: 'epc', order: 'descending'}">
		<el-table-column v-for="tableColumn, index in tableColumns" :label="tableColumn.label" :prop="tableColumn.prop"
			min-width="180" align="center" :sortable="tableColumn.sortable" :formatter="cellFormatter"
			:sort-method="getSortMethod(tableColumn.prop)">
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import {
		mapGetters
	} from 'vuex'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: () => []
			},
			tableColumns: {
				type: Array,
				default: () => []
			}
		},
		methods: {
			cellFormatter(row, column, cellValue, index) {
				if (/(estimatedRevenue)/i.test(column.property)) {
					return '$' + Number(cellValue || null).toFixed(2)
				}
				else if (/epc/i.test(column.property)) {
					const epc = Number(row.estimatedRevenue) / Number(row.clicks)
					const formatted = (isFinite(epc) ? Number(epc).toFixed(3) : '0.000')
					return '$' + formatted.substring(0, formatted.length - 1)
				}
				return cellValue
			},
			// 根据 prop 动态返回排序方法
			getSortMethod(prop) {
				if (prop === 'epc') {
					return this.sortEpc
				} else if (prop === 'clicks') {
					return this.sortClicks
				}
				return null; // 对于不需要自定义排序的列，返回 null 或 undefined
			},
			sortClicks(a, b) {
				return Number(a.clicks) - Number(b.clicks)
			},
			sortEpc(a, b) {
				const numA = Number(a.clicks) === 0 ? 0 : Number(a.estimatedRevenue) / Number(a.clicks)
				const numB = Number(b.clicks) === 0 ? 0 : Number(b.estimatedRevenue) / Number(b.clicks)
				return numA - numB;
			}
		}
	}
</script>

<style>
	.jscx-terms-table.el-table .el-table__body tr:hover > td.el-table__cell {
		background-color: unset;
	}
	.jscx-terms-table.el-table th.el-table__cell > .cell {
		text-transform: capitalize;
	}
</style>